<template>
    <div>
        <section id="content" class="bg-light" style="overflow: inherit;">

			<div class="content-wrap">

				<div class="container">

					<div class="row">
						<div class="col-md-6">
							<h2 class="nott mb-5">Request for Custom Built Resource</h2>
							<!-- <p class="lead">Questions? Comments? Please fill out the Form or Email us at <a href="mailto:noreply@canvas.com">noreply@canvas.com</a>.</p> -->
							<!-- <p>Thank you for being in touch!</p> -->
							<div class="line line-sm"></div>
							<p>
								Thank you for your interest in eFlexervices! We truly hope that we can be the right partner for you. Please fill out this short questionnaire below. Please let us know a time when we can connect and we'll be sure to connect with you as soon as possible (less than 24 hours). We're looking forward to connecting with you!
							</p>
							<!-- <p>Collaboratively redefine fully tested processes and extensible partnerships. Professionally exploit team building opportunities rather than partner-focused e-markets. Objectively deliver functionalized platforms through partner-centric.</p> -->
						</div>
						<div class="col-md-6">
						<h4>Send us an Email</h4>

						<div class="form-widget" data-alert-type="inline">

							<div class="form-result"></div>

							<form class="row mb-0" id="shared-contactform" @submit="sendEmail" novalidate="true">
								<div class="form-process bg-transparent">
									<div class="css3-spinner">
										<div class="css3-spinner-scaler"></div>
									</div>
								</div>

								<div class="w-100"></div>
								<div class="col-md-6 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-name">Name <small>*</small></label>
									<input type="text" id="shared-contactform-name" name="shared-contactform-name" v-model="name" class="form-control form-control-pill required" />
								</div>

                                <div class="col-md-6 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-contact">Phone <small>*</small></label>
									<input type="text" id="shared-contactform-contact" name="shared-contactform-contact" v-model="contact" class="form-control form-control-pill" />
								</div>

                                <div class="w-100"></div>

								<div class="col-md-12 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-email">Email <small>*</small></label>
									<input type="email" id="shared-contactform-email" name="shared-contactform-email" v-model="email" class="required email form-control form-control-pill" />
								</div>

                                <div class="col-md-12 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-hours">How many hours per week do you think you'll need?  <small>*</small></label>
									<input type="number" min="1" max="168" id="shared-contactform-hours" name="shared-contactform-hours" v-model="reqhours" class="required form-control form-control-pill" />
								</div>

								<div class="w-100"></div>

                                <div class="col-md-6 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-time">When's the best time to have a call? <small>*</small></label>
									<!-- DateTime Picker -->
									<VueCtkDateTimePicker v-model="calltime" format="MM-DD-YYYY HH-mm" color="#1ABC9C" :behavior="{
										time: {
											nearestIfDisabled: false
										}
									}"/>
								</div>
                                <div class="w-100"></div>
                                <div class="col-md-6 form-group">
									<label class="nott ls0 font-weight-normal" for="timezone-offset">Your timezone <small>*</small></label>
									<select name="timezone_offset" id="timezone-offset" v-model="timezone" class="span5">
                                        <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                        <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                        <option value="-10:00">(GMT -10:00) Hawaii</option>
                                        <option value="-09:50">(GMT -9:30) Taiohae</option>
                                        <option value="-09:00">(GMT -9:00) Alaska</option>
                                        <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                        <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                        <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                        <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                        <option value="-04:50">(GMT -4:30) Caracas</option>
                                        <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                        <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                        <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                        <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                        <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                        <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                        <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                        <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                        <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                        <option value="+03:50">(GMT +3:30) Tehran</option>
                                        <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                        <option value="+04:50">(GMT +4:30) Kabul</option>
                                        <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                        <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                        <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                        <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                        <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                        <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                        <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                        <option value="+08:75">(GMT +8:45) Eucla</option>
                                        <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                        <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                        <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                        <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                        <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                        <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                        <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                        <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                        <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                        <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                    </select>
								</div>

                                <div class="w-100"></div>

								<div class="col-12 form-group">
									<label class="nott ls0 font-weight-normal" for="shared-contactform-message">Briefly describe what you're looking to achieve with a BPO partner <small>*</small></label>
									<textarea class="required form-control form-control-pill" id="shared-contactform-message" v-model="needs" name="shared-contactform-message" rows="6" cols="30"></textarea>
								</div>

								<div class="col-12 form-group d-none">
									<input type="text" id="shared-contactform-botcheck" name="shared-contactform-botcheck" class="form-control form-control-pill" />
								</div>

								<div class="col-md-12 form-group">
									<p v-if="errors.length">
										<b>Please fill in the missing details: </b>
										<ul>
											<li v-for="error in errors" :key="error" class="error">{{ error }}</li>
										</ul>
									</p>
								</div>
								
								<div class="col-12 form-group">
									<button class="button button-circle button-large m-0" type="submit">Send Message</button>
								</div>
								
							</form>
						</div>
						</div>
					</div>

				</div>
			</div>
		</section>
    </div>
</template>

<script>
import emailjs from 'emailjs-com'
import{ init } from 'emailjs-com'
init("user_6Z3KX7nNeTC8xvNoJzA8H");
export default {
    data() {
		return {
			errors: [],
			name: '',
			contact: '',
			email: '',
			reqhours: '',
			calltime: '',
			timezone: '-08:00',
			needs: '',
			resource: 'Custom Built',
			to_email: 'hello@eflexervices.com'
		}
	},
	methods: {
		sendEmail: function(e) {
			if (this.name && this.contact && this.email && this.reqhours && this.calltime && this.timezone && this.needs) {
				const templateParams = {
					from_name: this.name,
					contact: this.contact,
					from_email: this.email,
					reqhours: this.reqhours,
					calltime: this.calltime,
					timezone: this.timezone,
					needs: this.needs,
					resource: this.resource,
					to_email: this.to_email
				}
				emailjs.send('service_v5xtaud', 'template_0hm2q0v', templateParams,
				'user_6Z3KX7nNeTC8xvNoJzA8H'
				)
				this.$router.push('/thankyou')
			}
			this.errors=[]

			if(!this.name) {
				this.errors.push('Name Required')
			}
			if(!this.contact) {
				this.errors.push('Phone Number Required')
			}
			if(!this.email) {
				this.errors.push('Email Required')
			} else if(!this.validEmail(this.email)) {
				this.errors.push('Valid Email Required')
			}
			if(!this.reqhours) {
				this.errors.push('Requested Number of Hours')
			}
			if(!this.calltime) {
				this.errors.push('Best time to call')
			}
			if(!this.timezone) {
				this.errors.push('Your Timezone')
			}
			if(!this.needs) {
				this.errors.push("What you're looking for in a BPO")
			}
			e.preventDefault()
		},
		validEmail: function(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email)
		}
	},
	mounted() {
		window.scrollTo(0, 0)
	}
}
</script>

<style scoped>
.error {
	list-style: none;
}
</style>